import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { node } from 'prop-types';

import Favicon from '@assets/images/DUC_8452.jpeg';
import '@assets/css/icomoon.css';
import '@assets/css/bootstrap.css';
import '@assets/css/style.css';

const IMAGE_URL = `https://tuanhuong.pages.dev/static/DUC_7865-01-7a4356fafd2c4b854807200d4835dc12.jpeg`;

function MainLayout({ children }) {
  return (
    <Fragment>
      <Helmet>
        <title>Tuấn ❤️ Hương Wedding</title>

        {/* Favicon */}
        <link rel="icon" type="image/png" href={Favicon} />

        {/* font and SEO tags */}
        <meta property="og:title" content="The Wedding of Tuấn & Hương" />
        <meta property="og:image" content={IMAGE_URL} />
        <meta property="og:url" content="https://tuanhuong.pages.dev" />
        <meta property="og:site_name" content="The Wedding of Tuấn & Hương" />
        <meta property="og:description" content="The Wedding of Tuấn & Hương" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Charm&family=Patrick+Hand&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Cookie&display=swap" rel="stylesheet"></link>
      </Helmet>
      <div id="page">{children}</div>
    </Fragment>
  );
}

MainLayout.propTypes = {
  children: node.isRequired,
};

export default MainLayout;
